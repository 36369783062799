<template>
    <div class="full-height color-txt">
        <s-form class="row">
            <div class="col-12">
                <h3 class="text-primary fs-lg-4 font-poppins-semibold">Reports</h3>
            </div>
            <div class="col-lg-6 mt-lg-5">

                <validated-select class="c-input-select input-border-primary" label="Other Reports" placeholder="Choose Report"
                                  v-model="reportType"
                                  :options="reportOptions"></validated-select>
            </div>
        </s-form>
        <div class="row">
            <div class="col-12" v-if="reportType==='Abstract of observation done to a particular Bull/Cow date wise'">
                <abstract-of-observation/>
            </div>

        </div>
    </div>

</template>

<script>

import AbstractOfObservation from './reportComponents/AbstractOfObservation';
export default {
    name: 'commonReportHome',
    components: {
        AbstractOfObservation

    },
    data () {
        return {
            reportType: '',
            reportOptions: [
                { value: 'Abstract of observation done to a particular Bull/Cow date wise', label: 'Abstract of observation done to a particular Bull/Cow date wise' }

            ]
        };
    }
};
</script>

<style scoped>
</style>
